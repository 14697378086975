import React, {useEffect} from "react";
import {useAuth} from "../components/auth.context";
import {Navigate} from "react-router-dom";

const Login:React.FC<{}> = () => {
    const auth = useAuth();
    useEffect(()=>{
        if(!auth.keycloak.authenticated) {
            auth.keycloak.login({
                redirectUri:"https://dsl-editor.apps.eo4eu.eu/",
                //redirectUri:"http://localhost:3000/"
            }).then()
        }
    },[])

    if(auth.keycloak.authenticated) {
        return <Navigate to={"/"}/>
    }
    return null;
}

export default Login;
